import $ from 'jquery';

// loading animation elements
const innerHeight = window.innerHeight;
const logo = document.querySelector('.loading-display__logo');
const loading = document.getElementById('loading');
const fvLogo = document.querySelector('.fv-pc__logo-area');
// fix .fv-sp__triangle aspect ratio for ios
const spTriangle = document.querySelector('.fv-sp__triangle');
const isIOS = /[ \(]iP/.test(navigator.userAgent);

// スマホ時でもローディングを上下中央に揃える
loading.style.height = `${innerHeight}px`

//　ページのロードが完了したら disappearLoading メソッドを実行
window.addEventListener('load', () => {
    disappearLoading();
});

// 6秒以経過で、強制的にdisappearLoading メソッドを実行
setTimeout(disappearLoading(), 6000);

// ローディング画面を非表示にするメソッド
function disappearLoading() {
    // 読み込み完了の2秒後にfadeOutのアニメーションを実行
    setTimeout(() => {
        loading.style.animationName = "fadeOut";
        addEventListener('animationend', () => {
            setTimeout(() => {
                loading.style.display = "none";
                // ローディング画面の表示が消えたら、fvのロゴがスライドイン
                if(getComputedStyle(loading, null).getPropertyValue('display') == "none") {
                    fvLogo.style.animationName = "slideIn";
                }
            }, 500)
        });
    }, 2000);
}

// iosのときに.fv-sp__triangleのheightを固定する
if(isIOS) {
    // init
    const targetWidth = spTriangle.clientWidth;
    spTriangle.style.height = `${targetWidth}px`;
    // resize method
    window.addEventListener('resize', () => {
        const targetWidth = spTriangle.clientWidth;
        spTriangle.style.height = `${targetWidth}px`;
    });
}

// 記事の取得
$.ajax({
    type: "GET",
    dataType: "json",
    // TASK: 本番環境の際にURLを変更
    url: "https://habukoujiten.tohokubiz.com/works/wp-json/wp/news"
}).then((res) => {
    let i = 0;
    const data = res;
    const target = document.getElementById('news-list');
    const targetSp = document.getElementById('news-list-sp');

    if(res.length !== 0) {
        data.forEach((res) => {
            // 記事を3件のみ取得
            if(i < 3) {
                const linkUrl = location.href + 'news/#' + res.id;
                const dateTag = res.dateForTag;
                const dateUi = res.dateForUi;
                const title = res.title;
                const insertElement = `
                <li class="news">
                    <a href="${linkUrl}">
                        <time datetime="${dateTag}" itemprop="datepublished">${dateUi}</time>
                        <h3 class="news-title">${title}</h3>
                        <svg class="arrow" xmlns="http://www.w3.org/2000/svg" id="arrow" width="8.652" height="16.104" viewBox="0 0 8.652 16.104"><path d="M475.626,1352.3l-.712-.7,7.247-7.35-7.247-7.35.712-.7,7.94,8.052Z" transform="translate(-474.914 -1336.192)"/></svg>
                    </a>
                </li>`;
                
                target.insertAdjacentHTML('beforeend', insertElement);
                targetSp.insertAdjacentHTML('beforeend', insertElement);
                // カウンター
                i++;
            }
        });
    } else {
        target.insertAdjacentHTML('beforeend', '<p class="no-article">新着の情報はありません</p>')
        targetSp.insertAdjacentHTML('beforeend', '<p class="no-article">新着の情報はありません</p>')
    }

    return false;
}).catch((e) => {
    const target = document.getElementById('news-list');
    const targetSp = document.getElementById('news-list-sp');

    target.insertAdjacentHTML('beforeend', '<p class="no-article">記事の取得が出来ませんでした</p>');
    targetSp.insertAdjacentHTML('beforeend', '<p class="no-article">記事の取得が出来ませんでした</p>');

    console.trace(e);
});